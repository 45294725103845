<template>
  <div class="home" style="margin-top: 30px;">
    <a v-if="role === 'ADMIN'" href="/#/stats">
      <img style="position: absolute; top: 35px; left: 30px"
        src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.iconsdb.com%2Ficons%2Fdownload%2Fwhite%2Fbar-chart-5-512.png&f=1&nofb=1&ipt=8a2679151a6dda738f65b5d79691eeb076bdc0437b2300d347beaa1521fcdac5&ipo=images"
        height="25" />
    </a>
    <a href="/#/"><img height="25" src="/logo.svg" style="margin-bottom: 20px;" /></a>
    <hr />
    <a v-if="role === 'ADMIN'" href="/#/whitelist">
      <img style="position: absolute; top: 35px; left: 75px"
        src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.iconsdb.com%2Ficons%2Fpreview%2Fwhite%2Fqr-code-xxl.png&f=1&nofb=1"
        height="25" /></a>
    <a @click="logout">
      <img style="position: absolute; top: 35px; right: 40px"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/OOjs_UI_icon_logOut-ltr-invert.svg/1024px-OOjs_UI_icon_logOut-ltr-invert.svg.png"
        height="30" /></a>
    <br />
    <div v-if="!inited" style="padding: 25vh 0">
      Please allow the camera.<br /><br />
      <button style="padding: 20px; font-family: 'Azeret Mono', monospace;width: 100%;font-size: 30px;"
        v-on:click="initCamera()">
        ALLOW CAMERA</button><br /><br />
    </div>
    <br />
    <div v-show="inited && !checked && !checking">
      <video id="qrscanner" style="width:100%; height:calc(90vh - 200px)"></video>
    </div>
    <div v-if="inited && !checked && !checking"><br /><br />Scanner ready.</div>
    <div v-if="checking"><br /><br />Checking with blockchain..</div>
    <div v-if="checked && valid && !isAdmitting" style="color: #03a062; font-size: 25px; padding: 30px 0">
      <div>
        TICKET VALID!<br><br>
        <span style="font-size:16px">{{ tier.event_name }}</span>
        <br><br>
        <div v-for="perk in tier.perks" v-bind:key="perk.name">
          <div v-if="gate.perks.indexOf(perk.name) !== -1">
            <button v-if="!admissions[perk.name]" style="height: 40px" v-on:click="enter(perk.name)">
              {{ perk.name }} (0/{{ perks[perk.name] }})
            </button>
            <button v-if="admissions[perk.name]" style="height: 40px; opacity:0.5">
              {{ perk.name }} ({{ Object.keys(admissions[perk.name]).length }}/{{ perks[perk.name] }})
            </button>
          </div>
        </div>
        <button @click="restart" style="height: 40px; position:fixed;bottom:0;left:0;">
          RESTART
        </button>
      </div>
    </div>
    <div v-if="isAdmitting" :style="{ color: admitColor }" class="admitting">
      <div v-if="admitResponse">{{ admitResponse }}</div>
      <div v-if="!admitResponse">Talking with admission service, please wait..</div>
    </div>
    <div v-if="checked && !valid" style="color: #f00000; font-size: 50px; padding: 20vh 0">
      CLAIM IS NOT VALID!
    </div>
  </div>
</template>

<script>
import axios from "axios";
import QrScanner from 'qr-scanner';

export default {
  name: "Home",
  data() {
    return {
      role: "",
      inited: false,
      allow: false,
      valid: false,
      checked: false,
      tier: "",
      isAdmitting: false,
      claim: "",
      encryptedClaim: "",
      tierId: "",
      admissions: null,
      perks: {},
      gate: {},
      admitResponse: "",
      admitColor: "#fff",
      ready: true,
      checking: false,
      endpointUrl: process.env.VUE_APP_ENDPOINT_URL,
      axios: axios,
      qrInstance: {}
    };
  },
  mounted() {
    const app = this
    app.role = localStorage.getItem("role")
    app.gate = JSON.parse(localStorage.getItem("gate"))
  },
  methods: {
    restart() {
      const app = this
      app.claim = ""
      app.checked = false;
      app.ready = true;
      app.admissions = "";
    },
    async checkQR(claim) {
      const app = this;
      if (!app.checking) {
        app.encryptedClaim = claim
        console.log("Encrypted claim: " + claim);
        console.log("Checking..");
        app.checking = true;
        app.valid = false;
        app.checked = false;
        const validated = await axios.post(app.endpointUrl + "/claims/validate", {
          claim,
        });
        console.log("VALIDATION_RESPONSE", validated.data);
        if (
          validated.data.error !== undefined &&
          validated.data.error === false
        ) {
          app.valid = true;
          app.checked = true;
          app.checking = false;
          app.claim = validated.data.claim.tokenId;
          app.tierId = validated.data.tierId;
          app.admissions = validated.data.admissions;
          for (let k in validated.data.tier.perks) {
            app.perks[validated.data.tier.perks[k].name] = validated.data.tier.perks[k].amount
          }
        } else {
          app.checking = false;
          app.checked = true;
          app.valid = false;
          setTimeout(function () {
            app.ready = false;
            setTimeout(function () {
              app.checked = false;
              app.ready = true;
            }, 100);
          }, 1500);
        }
        app.tier = validated.data.tier;
      }
    },
    async enter(perk) {
      const app = this;
      const ticket = app.claim;
      console.log("Entering with ticket:", ticket);
      console.log("Using perk:", perk)
      app.isAdmitting = true;
      const admit = await axios.post(
        process.env.VUE_APP_ENDPOINT_URL + "/admit/claim",
        {
          claim: app.encryptedClaim,
          passcode: app.gate.passcode,
          perk
        }
      );
      console.log("ADMISSION_RESPONSE", admit.data);
      if (admit.data.message !== undefined) {
        app.admitResponse = admit.data.message;
        if (admit.data.error) {
          app.admitColor = "#f00";
        } else {
          app.admitColor = "#03a062";
        }
      } else {
        alert("Something goes wrong, please retry!");
      }
      setTimeout(function () {
        app.isAdmitting = false;
        app.admitResponse = "Admitting token, please wait..";
        app.admitColor = "#fff";
        app.checked = false;
        app.ready = true;
        app.admission = "";
      }, 1500);
    },
    initCamera() {
      const app = this
      app.inited = true
      setTimeout(function () {
        const videoElem = document.getElementById("qrscanner")
        app.qrInstance = new QrScanner(
          videoElem,
          result => {
            if (result.data !== undefined) {
              app.checkQR(result.data)
            }
          },
          { highlightScanRegion: true, highlightCodeOutline: true, preferredCamera: "environment" },
        );
        app.qrInstance.start();
      }, 500)
    },
    logout() {
      localStorage.setItem("entered", "");
      localStorage.setItem("role", "");
      localStorage.setItem("gate", "");
      localStorage.setItem("eventId", "")
      location.reload()
    }
  },
};
</script>

<template>
  <div>Configuring..</div>
</template>

<script>

export default {
  name: "Configure",
  mounted() {
    localStorage.setItem("eventId", this.$route.params.eventId)
    window.location.href = '/#/'
    location.reload()
  },
};
</script>

<template>
  <div id="app">
    <div v-if="!noconfig || configuring">
      <router-view v-if="unlocked || configuring" />
      <div v-if="!unlocked" style="margin-top: 30px">
        <img width="90" src="/logo.svg" style="margin-bottom: 20px" />
        <hr />
        <br />
        <div v-if="!accessing">
          <div style="height: 30px">
            <span v-for="digit in pin" style="font-size: 30px" v-bind:key="digit">*</span>
          </div>
          <br />
          <div v-for="button in pinpad" v-bind:key="button">
            <div v-on:click="addPin(button)" class="button">
              {{ button }}
            </div>
          </div>
        </div>
        <div v-if="accessing" style="padding: 35vh 0">
          {{ workingMessage }}
        </div>
      </div>
    </div>
    <div v-if="noconfig" style="padding:45vh 90px">
      Please configure gate first, go to admin panel and click "Open Gate Page" in event page.
    </div>
  </div>
</template>

<style>
body,
html {
  overflow-x: hidden;
}

#app {
  font-family: "Azeret Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

.button {
  width: 28%;
  float: left;
  margin: 1%;
  font-family: "Azeret Mono", monospace;
  font-size: 4rem;
  padding: 5px;
  height: 18vh;
}

html,
body {
  background: #000000;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
import axios from "axios"
import sha256 from "sha256"

export default {
  name: "Main",
  data() {
    return {
      role: "",
      unlocked: false,
      endpointUrl: process.env.VUE_APP_ENDPOINT_URL,
      pinpad: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      pin: "",
      noconfig: false,
      configuring: false,
      workingMessage: "",
      roles: {
        gate: "259925",
        admin: "176678"
      },
      accessing: false,
    };
  },
  mounted() {
    const app = this;
    if (app.$route.params.eventId !== undefined) {
      app.configuring = true
    }
    const check = localStorage.getItem("entered");
    const eventId = localStorage.getItem("eventId")
    if (eventId !== null && eventId.length > 0) {
      if (check !== null && check !== undefined && check.length > 0) {
        app.accessing = false;
        app.unlocked = true;
      }
    } else {
      app.noconfig = true
    }
  },
  watch: {
    async pin() {
      const app = this;
      if (app.pin.length === 6 && !app.accessing) {
        app.accessing = true
        app.workingMessage = "Checking passcode.."
        console.log("Checking passcode..")
        const check = await axios.post(app.endpointUrl + "/admit/gate", {
          event: localStorage.getItem("eventId"),
          passcode: sha256(app.pin)
        })
        if (!check.data.error) {
          console.log("Passcode correct.");
          localStorage.setItem("entered", "Y");
          localStorage.setItem("gate", JSON.stringify(check.data.gate));
          localStorage.setItem("role", "GATE");
          // if (app.pin === app.roles.admin) {
          //   localStorage.setItem("role", "ADMIN");
          // }
          app.workingMessage = "Passcode correct, entering.."
          setTimeout(function () {
            app.unlocked = true;
            app.accessing = false;
          }, 1000);
        } else {
          app.accessing = false
          app.pin = ""
          alert("Passcode not correct!")
        }
      }
    },
  },
  methods: {
    addPin(num) {
      this.pin += num;
    }
  },
};
</script>

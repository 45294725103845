<template>
  <div class="home" style="margin-top: 30px">
    <a href="/#/">
      <img
        style="position: fixed; top: 25px; left: 30px"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAaVBMVEUAAAD////m5ub29vYjIyOgoKD39/fz8/Ojo6MlJSUfHx+dnZ0bGxspKSkgICB8fHzc3NwXFxe7u7tjY2Nvb29VVVWWlpbCwsI5OTm0tLQPDw+Kiopqamrs7OxMTExBQUGrq6vR0dGGhoZaLh+lAAACWklEQVR4nO3c7VLqMBSF4W5EzwFFQD2i4vf9X+QBZ2SkNvlj0uWsvM8V7DW7TZq0TdcBAAAAAAAAAAAAAAAAAAAAAAAAAACgnLvr5c0/dREVLSfx4X6mrqSOh1UcLNXF1LA4iS/O1eWUd3kUMOJGXVBp017AOFNXVNjFn+jbqmsqat7v4M5KXVRJ0+8d3FFXVdD8bChgzNV1FTMb7GDEVF1YKf1pwu4qnaUCuow03+bBA5PZYpEMaDLjpzsYj+raipgNTxN7t+raikhfoiZLi0wHPQK23MFTdW1FZDroETAzTXgETD6quQS8TKwmbAJO3QeZ4RW9UcDEit4noH8H3UdR+w4ObPx++quurYjMPOgR0L6DmUHGI6B9B9fuHZynO+ix6bROB4zVpJrN9mKkgJl7sLZxboFcB6ubjJHwSRhwlIgbacCI+9oBn8UBI14qJ7xSB6zeRO1duPdUOaE6X1R/Ya6OFyT8Mf/7UD+WXlVOqJ8PnysnlD/TbGoH7Drlg3f9u3BPurYYI6B0ffg6SkDZGv9tux4pYAP7NA3stTWwX7qLSBcN2L97aqKL9u+AG3iP30YX3b+naaKL9t+1NfBtYgOfQDfwCW0DX+o38LcFXfSQ+j3WKGLmMdxleyqzXnQ5NSL974zJP6S5fVST/4AzKw2Xf7kzw426sHJSk4bPqRGpVb/NqRFdA2ebDK/6fUaaDwPz4ru6psIW/S6eqCsqrr/qv1YXVN7xetHlyfvI1zP33G7CT4dzExfqSupxP/sSAAAAAAAAAAAAAAAAAAAAAAAAAADgt/oPPIkcVNWYeXgAAAAASUVORK5CYII="
        height="50"
    /></a>
    <img height="30" src="/logo.svg" style="margin-bottom: 20px" />
    <hr />
    <div v-if="account">
      <br />
      Connected with:<br />
      <span style="font-size: 11px">{{ account }}</span
      ><br /><br />
      <div v-if="haveAccess && !isChecking" style="padding-bottom: 120px">
        Wallet owns {{ owned.length }} ticket<span
          v-if="owned.length > 1 || owned.length === 0"
          >s</span
        >.
        <br />
        <div v-if="!isAdmitting">
          <div v-for="ticket in owned" v-bind:key="ticket.toString()">
            <button v-on:click="enter(ticket.toString())">
              ENTER WITH TICKET #{{ ticket }}
            </button>
          </div>
        </div>
        <div
          v-if="isAdmitting"
          :style="{ color: admitColor }"
          class="admitting"
        >
          {{ admitResponse }}
        </div>
        <button
          style="position: fixed; bottom: 0; left: 0; height: 80px"
          v-on:click="disconnect"
        >
          DISCONNECT
        </button>
      </div>
      <div v-if="!haveAccess && !isChecking">
        Sorry, seems you don't have access to the event!
      </div>
      <div v-if="isChecking">Checking if you own the ticket..</div>
    </div>
    <div v-if="!account">
      <div style="padding: 30vh 0">
        Please connect your wallet or type<br /><br />
        <input v-model="account" /><br />
        <button v-on:click="connect" style="height: 80px">CONNECT</button>
      </div>
    </div>
  </div>
</template>
<style>
input {
  width: 100%;
  height: 40px;
  font-size: 22px;
  padding: 5px;
}
button {
  font-size: 14px;
  padding: 10px;
  width: 100%;
  margin-top: 20px;
  font-family: "Azeret Mono", monospace;
}
.admitting {
  text-align: center;
  padding: 25vh 0;
  font-size: 30px;
}
</style>
<script>
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { ethers } from "ethers";
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      haveAccess: false,
      isChecking: false,
      isAdmitting: false,
      admitColor: "#fff",
      owned: [],
      connector: "",
      account: "",
      admitResponse: "Admitting token, please wait..",
      signature: "",
      dummy_key:
        "0xe884f9ca0966609447242257f4d45601f887a1d670076ca5dc7a58bdc99645b7",
      contract_address: "0xaDAe0946994ed88eA2Ef3A95adBf771B61b5e738",
      ABI: [
        {
          inputs: [
            {
              internalType: "address",
              name: "_owner",
              type: "address",
            },
          ],
          name: "tokensOfOwner",
          outputs: [
            {
              internalType: "uint256[]",
              name: "ownerTokens",
              type: "uint256[]",
            },
          ],
          stateMutability: "view",
          type: "function",
          constant: true,
        },
      ],
    };
  },
  watch: {
    account() {
      const app = this;
      console.log(app.account.length);
      if (app.account.length === 42) {
        app.checkNFT();
      }
    },
  },
  methods: {
    connect() {
      const app = this;
      app.connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal,
      });
      if (app.connector.connected) {
        try {
          app.connector.killSession();
          setTimeout(function () {
            app.connect();
          }, 300);
        } catch (e) {
          console.log(e.mesage);
        }
      } else {
        app.connector.createSession();
        // Subscribe to connection events
        app.connector.on("connect", (error, payload) => {
          if (error) {
            throw error;
          }
          // Get provided accounts and chainId
          const { accounts } = payload.params[0];
          app.account = accounts[0];
          app.checkNFT();
        });
      }
    },
    async checkNFT() {
      const app = this;
      app.isChecking = true;
      const provider_url =
        "https://polygon-mainnet.g.alchemy.com/v2/9pcMuNxJCv417rZZWTINla3epu3lexpT";
      const provider = new ethers.providers.JsonRpcProvider(provider_url);
      let wallet = new ethers.Wallet(app.dummy_key).connect(provider);
      const contract = new ethers.Contract(
        app.contract_address,
        app.ABI,
        wallet
      );
      const owned = await contract.tokensOfOwner(app.account);
      console.log("Owned nfts are:", owned);
      app.owned = owned;
      if (owned.length > 0) {
        app.haveAccess = true;
      }
      app.isChecking = false;
    },
    disconnect() {
      const app = this;
      try {
        app.connector.killSession();
      } catch (e) {
        console.log("No wallet connect session");
      }
      app.account = "";
      app.signature = "";
      app.owned = [];
      app.haveAccess = false;
    },
    async enter(ticket) {
      const app = this;
      console.log("Entering with ticket:", ticket);
      app.isAdmitting = true;
      const admit = await axios.post(
        process.env.VUE_APP_ENDPOINT_URL + "/admit/token",
        {
          token: ticket,
          secret: process.env.VUE_APP_ADMIT_SECRET,
        }
      );
      console.log(admit.data);
      if (admit.data.message !== undefined) {
        app.admitResponse = admit.data.message;
        if (admit.data.error) {
          app.admitColor = "#f00";
        } else {
          app.admitColor = "#03a062";
        }
      } else {
        alert("Something goes wrong, please retry!");
      }
      setTimeout(function () {
        app.isAdmitting = false;
        app.admitResponse = "Admitting token, please wait..";
        app.admitColor = "#fff";
      }, 1500);
    },
  },
};
</script>

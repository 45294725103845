import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Connect from '../views/Connect.vue'
import Stats from '../views/Stats.vue'
import Whitelist from '../views/Whitelist.vue'
import Configure from '../views/Configure.vue'
// import Halo from '../views/Halo.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/configure/:eventId',
        name: 'Configure',
        component: Configure
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/connect',
        name: 'Connect',
        component: Connect
    },
    {
        path: '/stats',
        name: 'Stats',
        component: Stats
    },
    {
        path: '/whitelist',
        name: 'Whitelist',
        component: Whitelist
    },
    // {
    //     path: '/halo',
    //     name: 'Halo',
    //     component: Halo
    // }
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router
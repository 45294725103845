<template>
  <div class="home" style="margin-top: 30px">
    <a href="/#/">
      <img style="position: fixed; top: 25px; left: 30px"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAaVBMVEUAAAD////m5ub29vYjIyOgoKD39/fz8/Ojo6MlJSUfHx+dnZ0bGxspKSkgICB8fHzc3NwXFxe7u7tjY2Nvb29VVVWWlpbCwsI5OTm0tLQPDw+Kiopqamrs7OxMTExBQUGrq6vR0dGGhoZaLh+lAAACWklEQVR4nO3c7VLqMBSF4W5EzwFFQD2i4vf9X+QBZ2SkNvlj0uWsvM8V7DW7TZq0TdcBAAAAAAAAAAAAAAAAAAAAAAAAAACgnLvr5c0/dREVLSfx4X6mrqSOh1UcLNXF1LA4iS/O1eWUd3kUMOJGXVBp017AOFNXVNjFn+jbqmsqat7v4M5KXVRJ0+8d3FFXVdD8bChgzNV1FTMb7GDEVF1YKf1pwu4qnaUCuow03+bBA5PZYpEMaDLjpzsYj+raipgNTxN7t+raikhfoiZLi0wHPQK23MFTdW1FZDroETAzTXgETD6quQS8TKwmbAJO3QeZ4RW9UcDEit4noH8H3UdR+w4ObPx++quurYjMPOgR0L6DmUHGI6B9B9fuHZynO+ix6bROB4zVpJrN9mKkgJl7sLZxboFcB6ubjJHwSRhwlIgbacCI+9oBn8UBI14qJ7xSB6zeRO1duPdUOaE6X1R/Ya6OFyT8Mf/7UD+WXlVOqJ8PnysnlD/TbGoH7Drlg3f9u3BPurYYI6B0ffg6SkDZGv9tux4pYAP7NA3stTWwX7qLSBcN2L97aqKL9u+AG3iP30YX3b+naaKL9t+1NfBtYgOfQDfwCW0DX+o38LcFXfSQ+j3WKGLmMdxleyqzXnQ5NSL974zJP6S5fVST/4AzKw2Xf7kzw426sHJSk4bPqRGpVb/NqRFdA2ebDK/6fUaaDwPz4ru6psIW/S6eqCsqrr/qv1YXVN7xetHlyfvI1zP33G7CT4dzExfqSupxP/sSAAAAAAAAAAAAAAAAAAAAAAAAAADgt/oPPIkcVNWYeXgAAAAASUVORK5CYII="
        height="50" /></a>
    <a href="/#/"><img height="25" src="/logo.svg" style="margin-bottom: 20px" /></a>
    <hr />
    <div v-if="stats.length > 0">
      <div v-for="event in stats" v-bind:key="event.tierId"
        style="padding:20px 20px; border-bottom:1px solid #fff; text-align: left;">
        <div style="font-size:20px; font-weight:bold; margin-bottom:10px">{{ event.event_name }}</div>
        <div v-for="perk in event.perks" v-bind:key="perk.name">
          - {{ perk.name }}
          <span style="float:right">{{ perksStats[event.tierId][perk.name] }} / {{ event.claims.length }}</span>
        </div>
      </div>
    </div>
    <div style="text-align:center; margin-top:30vh" v-if="stats.length === 0">
      Reading stats from service..
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      stats: [],
      perksStats: {},
      axios
    };
  },
  async mounted() {
    const app = this;
    app.role = localStorage.getItem("role")
    if (app.role === 'ADMIN') {
      const stats = await app.axios.post(process.env.VUE_APP_ENDPOINT_URL + "/stats/events", {
        secret: process.env.VUE_APP_ADMIT_SECRET,
        owner: process.env.VUE_APP_EVENT_OWNER
      })
      if (stats.data.stats !== undefined) {
        for (let k in stats.data.stats) {
          const event = stats.data.stats[k]
          if (app.perksStats[event.tierId] === undefined) {
            app.perksStats[event.tierId] = {}
            for (let j in event.perks) {
              app.perksStats[event.tierId][event.perks[j].name] = 0
            }
          }
          for (let k in event.admissions) {
            const admission = event.admissions[k]
            app.perksStats[event.tierId][admission.perk]++
          }
        }
        const sorted = stats.data.stats.sort((a, b) => {
          return b.price - a.price;
        });
        app.stats = sorted
      } else {
        alert("Something is wrong!")
      }
    } else {
      window.location = "/#/"
    }
  },
};
</script>

<template>
  <div class="home" style="margin-top: 30px">
    <a href="/#/">
      <img style="position: fixed; top: 25px; left: 30px"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAaVBMVEUAAAD////m5ub29vYjIyOgoKD39/fz8/Ojo6MlJSUfHx+dnZ0bGxspKSkgICB8fHzc3NwXFxe7u7tjY2Nvb29VVVWWlpbCwsI5OTm0tLQPDw+Kiopqamrs7OxMTExBQUGrq6vR0dGGhoZaLh+lAAACWklEQVR4nO3c7VLqMBSF4W5EzwFFQD2i4vf9X+QBZ2SkNvlj0uWsvM8V7DW7TZq0TdcBAAAAAAAAAAAAAAAAAAAAAAAAAACgnLvr5c0/dREVLSfx4X6mrqSOh1UcLNXF1LA4iS/O1eWUd3kUMOJGXVBp017AOFNXVNjFn+jbqmsqat7v4M5KXVRJ0+8d3FFXVdD8bChgzNV1FTMb7GDEVF1YKf1pwu4qnaUCuow03+bBA5PZYpEMaDLjpzsYj+raipgNTxN7t+raikhfoiZLi0wHPQK23MFTdW1FZDroETAzTXgETD6quQS8TKwmbAJO3QeZ4RW9UcDEit4noH8H3UdR+w4ObPx++quurYjMPOgR0L6DmUHGI6B9B9fuHZynO+ix6bROB4zVpJrN9mKkgJl7sLZxboFcB6ubjJHwSRhwlIgbacCI+9oBn8UBI14qJ7xSB6zeRO1duPdUOaE6X1R/Ya6OFyT8Mf/7UD+WXlVOqJ8PnysnlD/TbGoH7Drlg3f9u3BPurYYI6B0ffg6SkDZGv9tux4pYAP7NA3stTWwX7qLSBcN2L97aqKL9u+AG3iP30YX3b+naaKL9t+1NfBtYgOfQDfwCW0DX+o38LcFXfSQ+j3WKGLmMdxleyqzXnQ5NSL974zJP6S5fVST/4AzKw2Xf7kzw426sHJSk4bPqRGpVb/NqRFdA2ebDK/6fUaaDwPz4ru6psIW/S6eqCsqrr/qv1YXVN7xetHlyfvI1zP33G7CT4dzExfqSupxP/sSAAAAAAAAAAAAAAAAAAAAAAAAAADgt/oPPIkcVNWYeXgAAAAASUVORK5CYII="
        height="50" /></a>
    <a href="/#/"><img height="25" src="/logo.svg" style="margin-bottom: 20px" /></a>
    <hr />
    <div v-if="!tierId">
      <div v-if="stats.length > 0">
        <div v-for="event in stats" v-bind:key="event.tierId"
          style="padding:20px 90px 20px 20px; border-bottom:1px solid #fff; position:relative; text-align: left;">
          <div style="font-size:15px; font-weight:bold; margin-bottom:10px">{{ event.event_name }}</div>
          <img @click="selectEvent(event.tierId, event.event_name)"
            style="position: absolute; top: 17px; right: 30px; transform: rotate(180deg); cursor:pointer;"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAaVBMVEUAAAD////m5ub29vYjIyOgoKD39/fz8/Ojo6MlJSUfHx+dnZ0bGxspKSkgICB8fHzc3NwXFxe7u7tjY2Nvb29VVVWWlpbCwsI5OTm0tLQPDw+Kiopqamrs7OxMTExBQUGrq6vR0dGGhoZaLh+lAAACWklEQVR4nO3c7VLqMBSF4W5EzwFFQD2i4vf9X+QBZ2SkNvlj0uWsvM8V7DW7TZq0TdcBAAAAAAAAAAAAAAAAAAAAAAAAAACgnLvr5c0/dREVLSfx4X6mrqSOh1UcLNXF1LA4iS/O1eWUd3kUMOJGXVBp017AOFNXVNjFn+jbqmsqat7v4M5KXVRJ0+8d3FFXVdD8bChgzNV1FTMb7GDEVF1YKf1pwu4qnaUCuow03+bBA5PZYpEMaDLjpzsYj+raipgNTxN7t+raikhfoiZLi0wHPQK23MFTdW1FZDroETAzTXgETD6quQS8TKwmbAJO3QeZ4RW9UcDEit4noH8H3UdR+w4ObPx++quurYjMPOgR0L6DmUHGI6B9B9fuHZynO+ix6bROB4zVpJrN9mKkgJl7sLZxboFcB6ubjJHwSRhwlIgbacCI+9oBn8UBI14qJ7xSB6zeRO1duPdUOaE6X1R/Ya6OFyT8Mf/7UD+WXlVOqJ8PnysnlD/TbGoH7Drlg3f9u3BPurYYI6B0ffg6SkDZGv9tux4pYAP7NA3stTWwX7qLSBcN2L97aqKL9u+AG3iP30YX3b+naaKL9t+1NfBtYgOfQDfwCW0DX+o38LcFXfSQ+j3WKGLmMdxleyqzXnQ5NSL974zJP6S5fVST/4AzKw2Xf7kzw426sHJSk4bPqRGpVb/NqRFdA2ebDK/6fUaaDwPz4ru6psIW/S6eqCsqrr/qv1YXVN7xetHlyfvI1zP33G7CT4dzExfqSupxP/sSAAAAAAAAAAAAAAAAAAAAAAAAAADgt/oPPIkcVNWYeXgAAAAASUVORK5CYII="
            height="50" />
        </div>
      </div>
    </div>
    <div v-if="tierId" style="padding:0 15px">
      <h2 style="font-size:16px; padding:20px 0">{{ eventName }}</h2>
      <div v-show="inited">
        <video id="qrscanner" style="width:100%; height:calc(90vh - 200px)"></video>
      </div>
      <div v-if="!inited">
        <div style="padding-right:12px">
          <div style="position:relative">
            <input class="input" type="text"
              style="width: calc(100vw - 109px)!important;font-size: 12px; padding-right: 56px;" v-model="address"
              placeholder="Insert ETH address" />
            <img src="/qr-code.png" height="30" v-on:click="initCamera()" style="position:absolute; top:10px; right:-2px">
          </div>
          <input class="input" type="number" v-model="amount" placeholder="Amount of tickets" />
          <input class="input" type="number" max="100" min="0" v-model="discount" placeholder="Discount %" />
        </div>
        <button v-if="!isWorking" v-on:click="addToWhitelist" style="height: 80px">ADD TO WHITELIST</button>
        <div style="text-align: center; margin-top:20px" v-if="isWorking">{{ workingMessage }}</div>
      </div>
    </div>
    <div style="text-align:center; margin-top:30vh" v-if="stats.length === 0">
      Reading events from service..
    </div>
  </div>
</template>

<style>
.input {
  background: #000;
  border: 1px solid white;
  width: 100%;
  font-family: "Azeret Mono", monospace;
  text-align: center;
  color: #fff;
}
</style>
<script>
import axios from "axios";
import QrScanner from 'qr-scanner';

export default {
  name: "Home",
  data() {
    return {
      stats: [],
      perksStats: {},
      axios,
      eventName: '',
      tierId: '',
      discount: '',
      amount: '',
      inited: false,
      isWorking: false,
      workingMessage: "",
      address: ''
    };
  },
  async mounted() {
    const app = this;
    app.role = localStorage.getItem("role")
    if (app.role === 'ADMIN') {
      const stats = await app.axios.post(process.env.VUE_APP_ENDPOINT_URL + "/stats/events", {
        secret: process.env.VUE_APP_ADMIT_SECRET,
        owner: process.env.VUE_APP_EVENT_OWNER
      })
      if (stats.data.stats !== undefined) {
        for (let k in stats.data.stats) {
          const event = stats.data.stats[k]
          if (app.perksStats[event.tierId] === undefined) {
            app.perksStats[event.tierId] = {}
            for (let j in event.perks) {
              app.perksStats[event.tierId][event.perks[j].name] = 0
            }
          }
          for (let k in event.admissions) {
            const admission = event.admissions[k]
            app.perksStats[event.tierId][admission.perk]++
          }
        }
        const sorted = stats.data.stats.sort((a, b) => {
          return b.price - a.price;
        });
        app.stats = sorted
      } else {
        alert("Something is wrong!")
      }
    } else {
      window.location = "/#/"
    }
  },
  watch: {
    discount() {
      if (this.discount > 100) {
        this.discount = 100
      }
      if (this.discount < 0) {
        this.discount = 0
      }
    },
    amount() {
      if (this.amount < 0) {
        this.amount = 0
      }
    }
  },
  methods: {
    selectEvent(tierId, event_name) {
      this.tierId = tierId;
      this.eventName = event_name
    },
    initCamera() {
      const app = this
      app.inited = true
      setTimeout(function () {
        const videoElem = document.getElementById("qrscanner")
        app.qrInstance = new QrScanner(
          videoElem,
          result => {
            if (result.data !== undefined) {
              app.address = result.data.split("@")[0].replace("ethereum:", "").replace("polygon:", "")
              app.inited = false
            }
          },
          { highlightScanRegion: true, highlightCodeOutline: true, preferredCamera: "environment" },
        );
        app.qrInstance.start();
      }, 500)
    },
    async addToWhitelist() {
      const app = this
      if (app.discount > 0 && app.amount > 0 && app.address.length > 0 && !app.isWorking) {
        app.isWorking = true
        app.workingMessage = "Requesting to whitelist service.."
        const added = await app.axios.post(process.env.VUE_APP_ENDPOINT_URL + "/events/whitelist", {
          tierId: app.tierId,
          discount: app.discount,
          amount: app.amount,
          address: app.address,
          secret: process.env.VUE_APP_ADMIT_SECRET
        })
        app.isWorking = false
        if (!added.data.error) {
          alert(added.data.message)
          app.address = ""
          app.amount = ""
          app.tierId = ""
          app.discount = ""
        } else {
          alert(added.data.message)
        }
      }
    }
  }
};
</script>
